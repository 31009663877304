var isMobile = new Object();
isMobile.Android = function () {
	return navigator.userAgent.match(/Android/i);
};

isMobile.BlackBerry = function () {
	return navigator.userAgent.match(/BlackBerry/i);
};
isMobile.iOS = function () {
	return navigator.userAgent.match(/iPhone|iPad|iPod/i);
};

isMobile.iPad = function () {
	return navigator.userAgent.match(/iPad/i);
};
isMobile.Opera = function () {
	return navigator.userAgent.match(/Opera Mini/i);
};
isMobile.Windows = function () {
	return navigator.userAgent.match(/IEMobile/i);
};

isMobile.Firefox = function () {
	return navigator.userAgent.match(/Firefox/gi);
};
isMobile.InternetExplorer = function () {
	return navigator.userAgent.match(/MSIE/gi);
};
isMobile.Opera = function () {
	return navigator.userAgent.match(/Opera/gi);
};
isMobile.Safari = function () {
	return navigator.userAgent.match(/Safari/gi);
};
isMobile.any = function () {
	return (
		isMobile.Android() ||
		isMobile.BlackBerry() ||
		isMobile.iOS() ||
		isMobile.Opera() ||
		isMobile.Windows()
	);
};
if (isMobile.any() && isMobile.iPad() == null) {
	//return;
}

var ua = window.navigator.userAgent;
var is_ie = /MSIE|Trident/.test(ua);

/*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

var winWT = $(window).innerWidth();
var winHT = $(window).innerHeight();
var disableScrolling = function () {
	var x = window.scrollX;
	var y = window.scrollY;
	window.onscroll = function () { window.scrollTo(x, y); };
}
var enableScrolling = function () {
	window.onscroll = function () { };
}
window.onbeforeunload = function () {
	window.scrollTo(0, 0);
}

$(window).on('load', function () {
	window.scrollTo(0, 0);
});

$(document).ready(() => {
	function initSlimmenu(){
		if(winWT <= 1023){
			$(".slimmenu").slimmenu({
				resizeWidth: '1023',
				collapserTitle: 'Main Menu',
				animSpeed: 'medium',
				easingEffect: null,
				indentChildren: false,
				childrenIndenter: '&nbsp;'
			});
		}
	}
	initSlimmenu();

	// Define GSAP timelines for opening and closing animations
	let tlMenuOpen = gsap.timeline();
	let tlMenuClose = gsap.timeline();

	// Event handler for menu icon click
	$(".nav-icon").on('click', function () {
	if ($(this).hasClass("open")) {
		resetMobileMenu();
	} else {
		$(this).addClass("open");
		$('nav').removeClass('hide');
		$('body').css({ overflowY: 'hidden' });
		gsap.set(".overlay .slice", { width:0});
		gsap.set(".nav-hld ", { opacity:1});
		gsap.set(".nav-hld > ul > li", { opacity:0, y:'70%'});
		gsap.set(".company-info--lt", { opacity:0, y:'50%'});
		gsap.set(".company-info--rt", { opacity:0, y:'50%'});
		tlMenuOpen.add("openMenu")
				  .to(".overlay .slice", { duration: 0.3, width: '100%', ease: 'outpower2.out', stagger: -0.1 }, 'openMenu')
				  .to(".nav-hld > ul > li", { duration: 0.3, opacity: 1, y:0, ease: 'outpower2.out', stagger: 0.1}, 'openMenu+=0.5')
		          .to(".company-info--lt", { duration: 0.3, opacity: 1, y:0, ease: 'outpower2.in',}, 'openMenu+=1')
		          .to(".company-info--rt", { duration: 0.3, opacity: 1, y:0, ease: 'outpower2.in',}, 'openMenu+=1.05');
	}
	});

	// Function to reset/close the mobile menu
	function resetMobileMenu() {
		$(".nav-icon").removeClass("open");
		gsap.set(".overlay .slice", { width:'100%'});
		gsap.set(".nav-hld", { opacity:1});
		tlMenuClose.to(".nav-hld", { duration: 0.1, opacity: 0, ease: 'outpower2.out'})
					.to(".overlay .slice", { duration: 0.3, width: '0', ease: 'outpower2.out', stagger: -0.05, onComplete: function(){
						$('nav').addClass('hide');
					}});
		$('body').css({ overflowY: 'scroll' });
	}

	function SwitchTextAnimation() {
		var loaderTextArray = [];
		var textListArr = $('.loader-text-hld ul').find('li');
		var loaderAnimTimeline = gsap.timeline();
		$('body').css({overflow:'hidden'});
		$(textListArr).each(function(){
			var currTxt = $(this).text();
			loaderTextArray.push(currTxt);
		});
		gsap.delayedCall(0.5, () => {
			var textTimeline = gsap.timeline({ repeat: -1 });
			loaderTextArray.forEach((text, i) => {
				textTimeline.to(".switch-text", {
					duration: 0.5,
					innerHTML: text,
					ease: 'outpower2.out',
					onComplete: function() {
						if (i === loaderTextArray.length - 1) {
							textTimeline.pause();
							loaderAnimTimeline.to('.switch-text', { duration: 0.2, opacity: 0, ease: 'outpower2.out' })
							.to("#loader .slice", { duration: 0.7, height: 0, ease: 'outpower2.out', stagger: 0.1 })
							.set('#loader', { display:'none'});

							$('body').css({overflowY:'scroll'});
						}
					}
				});
			});
		});
	}
	SwitchTextAnimation();


	$(window).resize(function(){
		winWT = $(window).innerWidth();
		initSlimmenu();
	});
	$(window).scroll(function() {
		var scroll = $(window).scrollTop();
		if (scroll >= 100) {
			$('header').addClass("sticky");
		} else {
			$('header').removeClass("sticky");
		}
	});
});
  


// about time line slider

let $card = $(".card");
let $button = $(".shop");
let productInfo = $(".prod-info");

const createSleeveText = () => {
	$card.each((index, element) => {
		let content = $(element).find("p").text();
		let splitContent = content.split(" ");
		$(element).find("p").text("");
		splitContent.forEach((text, index) => {
			$(element).find("p").append(`
				<span class="sleeve-element">
 					<span class="slvd-text">${text} </span>
 				</span>
			`);
		});
	});
};

const setHeight = () => {
	$card.each((index, element) => {
		let $this = $(element);
		let setHeight = $this.outerHeight();
		$this
			.attr("data-height", setHeight)
			.height($this.find(".card_hero").height() + "px");
	});

	let slider = new Flickity(".card-parent", {
		pageDots: false,
		prevNextButtons: false,
		freeScroll: true
	});

	$(".card-parent").find(".flickity-viewport").css({ overflow: "visible" });
};

const expandCard = (event) => {
	let $this = $(event.currentTarget);
	let $cardParent = $this.parent();
	let $slvText = $(".slvd-text");

	if ($cardParent.hasClass("active")) {
		$cardParent.css({
			height: $cardParent.find(".card_hero").height() + "px"
		});
		$cardParent.removeClass("active");
		$cardParent.find(".slvd-text").each((index, textBlock) => {
			let $slvdTextEl = $(textBlock);
			$(textBlock).css({ "transition-delay": "0ms" });
		});
	} else {
		$cardParent.css({
			height: $cardParent.attr("data-height") + "px"
		});
		$this.parents(".card-parent").height($cardParent.attr("data-height") + "px");
		$cardParent.addClass("active");
		$cardParent.find(".slvd-text").each((index, textBlock) => {
			let delay = 30 * index + "ms";
			let $slvdTextEl = $(textBlock);
			$(textBlock).css({ "transition-delay": delay });
		});
	}
};

createSleeveText();
setHeight();
$button.on("click", (event) => expandCard(event));

